@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
/* purgecss start ignore */

body {
  margin: 0 !important;
  font-family: Poppins;
}

@font-face {
  font-family: 'Segoe';

  src: local('Segoe'), url(/static/media/Segoe.1dc286ee.ttf) format('truetype');

  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

/* purgecss end ignore */
/* purgecss start ignore */

/* purgecss end ignore */
