
body {
    margin: 0 !important;
    font-family: Poppins;
}

@font-face {
    font-family: 'Segoe';
    src: local('Segoe'), url(./Segoe.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}